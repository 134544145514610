import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true)
  const [bathroomState, setBathroomState] = useState(false);

  useEffect(
    () => {
      window.setInterval(() => {      
        fetch('https://bathroom.fyi/get_status')
        .then(res => res.text())
        .then(data => (setBathroomState(parseInt(data))))
        .then(setLoading(false));
      }, 2000);
      }, []
  );
  if (loading) { return (<div style={{backgroundColor:'#1b1c21'}}></div>)
  } else {
  if (bathroomState) {
    return (
      <div style={{backgroundColor:'#1b1c21'}}>
        <div className="App container-fluid w-50" style={{backgroundColor:'#1b1c21'}}>
          <svg className="align-self-center" viewBox="0 0 82 60" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="a" x2="0" y1="18.495" y2="47.547" gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff" offset="0"/>
            <stop stopColor="#fff" stopOpacity="0" offset="1"/>
            </linearGradient>
          </defs>
          <path transform="matrix(.62849 0 0 .62849 -370.66 -541.02)" d="m690.1 908.55a35.101 35.101 0 1 1 -70.203 0 35.101 35.101 0 1 1 70.203 0z" fill="#73d216" fillRule="evenodd"/>
          <g fill="#4e9a06" fillRule="evenodd">
            <path transform="matrix(.54483 0 0 .58416 -315.87 -500.21)" d="m649.09 892c-7.6185 2.49-13.156 9.6055-13.156 18.062 2e-5 10.525 8.5375 19.062 19.062 19.062 10.525-3e-5 19.062-8.5375 19.062-19.062-2e-5 -8.4579-5.5644-15.574-13.188-18.062v6.8125c4.0673 2.1357 6.9062 6.3406 6.9062 11.25 1e-5 7.0463-5.735 12.781-12.781 12.781s-12.781-5.735-12.781-12.781c-2e-5 -4.9066 2.8087-9.1136 6.875-11.25v-6.8125z"/>
            <path transform="matrix(.54483 0 0 .58416 -315.87 -500.21)" d="m651.78 887.91v22.625h6.4375v-22.625h-6.4375z"/>
          </g>
          <g fill="#eeeeec" fillRule="evenodd">
            <path transform="matrix(.54483 0 0 .54483 -315.87 -465.29)" d="m649.09 892c-7.6185 2.49-13.156 9.6055-13.156 18.062 2e-5 10.525 8.5375 19.062 19.062 19.062 10.525-3e-5 19.062-8.5375 19.062-19.062-2e-5 -8.4579-5.5644-15.574-13.188-18.062v6.8125c4.0673 2.1357 6.9062 6.3406 6.9062 11.25 1e-5 7.0463-5.735 12.781-12.781 12.781s-12.781-5.735-12.781-12.781c-2e-5 -4.9066 2.8087-9.1136 6.875-11.25v-6.8125z"/>
            <path transform="matrix(.54483 0 0 .54483 -315.87 -465.29)" d="m651.78 887.91v22.625h6.4375v-22.625h-6.4375z"/>
          </g>
          <path transform="matrix(.70156 0 0 .73625 -48.237 -3.6149)" d="m149.63 34.779a22.429 16.284 0 1 1 -44.857 0 22.429 16.284 0 1 1 44.857 0z" fill="url(#a)" fillRule="evenodd" opacity=".75"/>
          </svg>
        </div>
        <div style={{backgroundColor:'#1b1c21'}} className="container-fluid w-50 text-center"><button type="button" disabled className="btn btn-lg btn-outline-primary">The Bathroom is Open!</button></div>
      </div>
    );
  } else {
    return (
      <div style={{backgroundColor:'#1b1c21'}}>
        <div className="App container-fluid w-50" style={{backgroundColor:'#1b1c21'}}>
            <svg version="1.1" viewBox="0 0 82 60" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="a" x2="0" y1="18.495" y2="47.547" gradientUnits="userSpaceOnUse">
              <stop stopColor="#fff" offset="0"/>
              <stop stopColor="#fff" stopOpacity="0" offset="1"/>
              </linearGradient>
            </defs>
            <g fillRule="evenodd">
              <path transform="matrix(.62849 0 0 .62849 -370.66 -541.02)" d="m690.1 908.55a35.101 35.101 0 1 1-70.203 0 35.101 35.101 0 1 1 70.203 0z" fill="#f00"/>
              <g fill="#d40000">
              <path transform="matrix(.54483 0 0 .58416 -315.87 -500.21)" d="m649.09 892c-7.6185 2.49-13.156 9.6055-13.156 18.062 2e-5 10.525 8.5375 19.062 19.062 19.062 10.525-3e-5 19.062-8.5375 19.062-19.062-2e-5-8.4579-5.5644-15.574-13.188-18.062v6.8125c4.0673 2.1357 6.9062 6.3406 6.9062 11.25 1e-5 7.0463-5.735 12.781-12.781 12.781s-12.781-5.735-12.781-12.781c-2e-5-4.9066 2.8087-9.1136 6.875-11.25v-6.8125z"/>
              <path transform="matrix(.54483 0 0 .58416 -315.87 -500.21)" d="m651.78 887.91v22.625h6.4375v-22.625h-6.4375z"/>
              </g>
              <g fill="#eeeeec">
              <path transform="matrix(.54483 0 0 .54483 -315.87 -465.29)" d="m649.09 892c-7.6185 2.49-13.156 9.6055-13.156 18.062 2e-5 10.525 8.5375 19.062 19.062 19.062 10.525-3e-5 19.062-8.5375 19.062-19.062-2e-5-8.4579-5.5644-15.574-13.188-18.062v6.8125c4.0673 2.1357 6.9062 6.3406 6.9062 11.25 1e-5 7.0463-5.735 12.781-12.781 12.781s-12.781-5.735-12.781-12.781c-2e-5-4.9066 2.8087-9.1136 6.875-11.25v-6.8125z"/>
              <path transform="matrix(.54483 0 0 .54483 -315.87 -465.29)" d="m651.78 887.91v22.625h6.4375v-22.625h-6.4375z"/>
              </g>
              <path transform="matrix(.70156 0 0 .73625 -48.237 -3.6149)" d="m149.63 34.779a22.429 16.284 0 1 1-44.857 0 22.429 16.284 0 1 1 44.857 0z" fill="url(#a)" opacity=".75"/>
            </g>
            </svg>
        </div>
        <div style={{backgroundColor:'#1b1c21'}} className="container-fluid w-50 text-center"><button type="button" disabled className="btn btn-lg btn-danger">The Bathroom is Closed!</button></div>
      </div>
    );    
  }
}
}

export default App;